import React from 'react';
import {HeaderBar, FooterBar, MobileBar} from '../../components';
import styles from './style.module.css';

function MainContainer({children}) {
    return (<>
        <HeaderBar/>
        <div className={styles.main_container}>{children}</div>
        <FooterBar/>
        <MobileBar/>
    </>)
}

export default MainContainer;