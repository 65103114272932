import React from "react";
import "./style.module.css";
import vicJson from "../../assets/geojson/suburb-10-vic.json";
import vicRegionalJson from "../../assets/geojson/vic_regional.json";
// import ausJson from "../../assets/geojson/australian_postcodes.json";
import { GoogleMap, useJsApiLoader, Polygon } from "@react-google-maps/api";
import * as Geocoder from "google-geocoder";
// import * as NodeGeocoder from "node-geocoder";
// import { VIC_REGIONAL_POSTCODES } from "../../constants/data";

const API_KEY = "AIzaSyCz-lXoG-fWSsnujWYRDKUNfsc90tpMifA";

const containerStyle = {
  width: "100%",
  height: "calc(100vh - 128px)",
};

const center = {
  lat: -37.83427494434227,
  lng: 144.92647232040434,
};

const options2 = {
  fillColor: "#202222",
  fillOpacity: 0.5,
  strokeColor: "#101222",
  strokeOpacity: 1,
  strokeWeight: 0.5,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};
const options = {
  fillColor: "#FF1222",
  fillOpacity: 0.2,
  strokeColor: "#FF0000",
  strokeOpacity: 0.5,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

function MapGamePage() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
  });

  const [map, setMap] = React.useState(null);
  const [geocoder, setCoder] = React.useState(null);
  const [suburbs, setSuburbs] = React.useState(vicJson);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    // console.log(map);
    setMap(map);
    const g = Geocoder({ key: API_KEY });
    console.log(g);
    setCoder(g);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  // React.useEffect(()=>{
  //   const copy = [...vicJson];
  //   for(const vicSuburb of copy) {
  //     const suburbName = vicSuburb.properties.vic_loca_2;
  //     const ausSuburb = ausJson.find(elem => elem.locality === suburbName);
  //     if (ausSuburb) {
  //       const postal_code = ausSuburb.postcode;
  //       if (VIC_REGIONAL_POSTCODES.includes(Number(postal_code))) {
  //         copy[copy.indexOf(vicSuburb)].isRegional = true
  //       }
  //     }
  //     setSuburbs(copy);
  //   }
  // },[]);

  React.useEffect(() => {
    alert(
      "Take a guess where is regional area in Victoria? Click the suburb to reveal."
    );
  }, []);

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={6}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <></>
        {suburbs.map((suburb, index) => {
          const paths = suburb.geometry.coordinates[0].map((latlng) => {
            return { lat: latlng[1], lng: latlng[0] };
          });
          // console.log(paths);
          return (
            <Polygon
              onClick={() => {
                const isRegional = vicRegionalJson[index].isRegional;
                if (isRegional) {
                  const copy = [...suburbs];
                  copy[index].isRegional = true;
                  setSuburbs(copy);
                  alert("Yes this is regional area :)");
                } else {
                  alert("No, it is not regional area :(");
                }
              }}
              key={`poly${index}`}
              onLoad={console.log}
              paths={paths}
              options={suburbs[index]?.isRegional ? options2 : options}
            />
          );
        })}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

export default MapGamePage;
