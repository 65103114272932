import React from 'react';
import styles from './style.module.css';
import githubIcon from '../../assets/svg/github.svg';
// import instagramIcon from '../../assets/svg/instagram.svg';
import linkedinIcon from '../../assets/svg/linkedin.svg';
import portrait from '../../assets/img/portrait.jpeg';
import {Link} from "react-router-dom";

function AboutMePage() {
    return (<div className={styles.container}>
        <div className={styles.card_container}>
            <div className={styles.left}>
                <div className={styles.avatar_container}>
                    <img className={styles.avatar} src={portrait} alt={"portrait"}/>
                </div>
                <div className={styles.name}>Nathan Yang</div>
                <div className={styles.divider}/>
                <div className={styles.entitle}>UI/UX Developer</div>
                <div className={styles.spacer}/>
                <div className={styles.social_media_container}>
                    {/*<img className={styles.social_icon} src={instagramIcon} alt={'ins icon'}/>*/}
                    <img className={styles.social_icon} src={githubIcon} alt={'git icon'}
                    onClick={()=>window.open('https://github.com/dev-nathan-yang','_blank')}
                    />
                    {/*<img className={styles.social_icon} src={twitterIcon} alt={'tw icon'}/>*/}
                    <img className={styles.social_icon} src={linkedinIcon} alt={'in icon'}
                         onClick={()=>window.open('https://www.linkedin.com/in/haoqianyang/','_blank')}
                    />
                    {/*<img className={styles.social_icon} src={facebookIcon} alt={'fb icon'}/>*/}
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.title}>Hi</div>
                <div className={styles.sub_title}>I build apps & websites</div>
                <div className={styles.btn_container}>
                    <div className={styles.btn_primary}>
                        <Link to={'/portfolio'}>
                            Portfolio
                        </Link>
                    </div>
                    {/*<div className={styles.btn_secondary}>Services</div>*/}
                </div>
                <div className={styles.intro}>I've been in the industry for a couple of years now gaining experience in
                    UI/UX design. Having
                    working on applications in categories like education and real estate. I am confident to design what
                    you want :)
                </div>
                <div className={styles.intro}>Fascinated with modern designs and I believe it would help users to get
                    the most out of their
                    interaction.
                </div>
            </div>
        </div>
        <div className={styles.bg_holder}/>
    </div>)
}

export default AboutMePage;