import React from 'react';
import styles from './style.module.css';
// import facebookIcon from '../../assets/svg/facebook.svg';
// import githubIcon from '../../assets/svg/github.svg';
// import instagramIcon from '../../assets/svg/instagram.svg';
// import linkedinIcon from '../../assets/svg/linkedin.svg';
// import twitterIcon from '../../assets/svg/twitter.svg';
// import portrait from '../../assets/img/portrait.jpeg';
import {Link} from "react-router-dom";

function NotFoundPage() {
    return (<div className={styles.container}>
        <div className={styles.card_container}>
            <h1>Oops, seems nothing here...</h1>
            <h3>Please click <Link to={'/'}>here</Link> to go home</h3>
        </div>
        <div className={styles.bg_holder}></div>
    </div>)
}

export default NotFoundPage;