export const LINKS = [
    {text: 'About Me', href: '/'},
    {text: 'Portfolio', href: '/portfolio'},
    {text: 'Map Game', href: '/map-game'},
    // {text:'About Me',href:'/'},
    {text: 'Contact', href: '/contact'}
];

const arr1 = []
for(let i = 3211; i <=3232; i++) { arr1.push(i)}
arr1.push(3235, 3240, 3328)
arr1.push(3330, 3331, 3332, 3333, 3340, 3341, 3342)
for(let i = 3097; i <=3099; i++) { arr1.push(i)}
arr1.push(3139)
for(let i = 3233; i <=3234; i++) { arr1.push(i)}
for(let i = 3236; i <=3239; i++) { arr1.push(i)}
for(let i = 3241; i <=3325; i++) { arr1.push(i)}
arr1.push(3329, 3334, 3341)
for(let i = 3345; i <=3424; i++) { arr1.push(i)}
for(let i = 3430; i <=3799; i++) { arr1.push(i)}
for(let i = 3809; i <=3909; i++) { arr1.push(i)}
for(let i = 3912; i <=3971; i++) { arr1.push(i)}
for(let i = 3978; i <=3996; i++) { arr1.push(i)}
export const VIC_REGIONAL_POSTCODES = arr1;