import React, {useEffect, useState} from 'react';
import styles from './style.module.css';
import {Link} from "react-router-dom";
import {LINKS} from "../../constants/data";
import menuIcon from '../../assets/svg/menu.svg';

function MobileBar() {

    const [toggle, setToggle] = useState(false);

    const [opacityS, setOpacityS] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpacityS(toggle ? 1 : 0)
        }, 200);
        return () => clearTimeout(timer);
    }, [toggle])

    const toggleHandler = () => {
        let timer;
        if (!toggle) {
            setToggle(true);
            timer = setTimeout(() => {
                setOpacityS(1);
                clearTimeout(timer);
            }, 100);
        } else {
            setOpacityS(0);
            timer = setTimeout(() => {
                setToggle(false);
                clearTimeout(timer);
            }, 200);
        }

    }

    return (
        <>
            <div className={styles.overlay} style={{display: toggle ? null : "none", opacity: opacityS}}
                 onClick={toggleHandler}/>
            <div className={styles.title}>
                <img src={menuIcon} onClick={toggleHandler} alt='menu'/>
            </div>
            <div className={styles.mobile_links} style={{
                // display: toggle ? null : "none",
                // opacity: opacityS,
                transform: toggle ? "translateX(0)":"translateX(100%)"}}>
                <div className={styles.close} onClick={toggleHandler}>✕</div>
                {LINKS.map((elem, index) => (
                    <div key={'link' + index}>
                        <Link to={elem.href}>{elem.text}</Link>
                    </div>
                ))}
            </div>

        </>
    )
}

export default MobileBar;