import React, {useState} from 'react';
import styles from './card.module.css';


function ContactFormCard() {

    const [senderName, setSenderName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');


    const submitHandler = (evt) => {
        evt.preventDefault();
        window.location.href = `mailto:nathan@nathany.me?subject=${subject}&body=Hi, this is ${senderName}, ${message} ${email}`;
    }


    return (
        <div className={styles['container']}>
            <form
                onSubmit={submitHandler}
                className={styles['form']}>
                <input className={styles['form-input']} placeholder={'Name'}
                       value={senderName}
                       onInput={e => setSenderName(e.target.value)}
                />
                <input className={styles['form-input']} placeholder={'Email'}
                       value={email}
                       onInput={e => setEmail(e.target.value)}
                />
                <input className={styles['form-input']} placeholder={'Subject'}
                       value={subject} onInput={e => setSubject(e.target.value)}/>
                <textarea className={styles['form-input']} placeholder={'Message'} rows={4}
                          value={message} onInput={e => setMessage(e.target.value)}
                />
                <div/>
                <div/>
                <input type={'submit'} className={styles['form-submit-btn']} value={'Send'}/>
            </form>
        </div>
    );
}

export default ContactFormCard;