import React from 'react';
import styles from './style.module.css';

function FooterBar() {
    return (
        <>
            {/*<div className={styles.placeholder}/>*/}
            <div className={styles.container}>
                <div className={styles.content_wrapper}>
                    <div className={styles.name}>©2021 by Nathan Yang</div>
                    {/*<div className={styles.links}></div>*/}
                    <div className={styles.spacer} />
                    <div className={styles.links}>
                       <a href={'mailto:nathan@nathany.me'}>nathan@nathany.me</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterBar;