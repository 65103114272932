import React from 'react';
import styles from './style.module.css';
import {ContactFormCard} from "../../components";

function ContactPage() {
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Let's Talk</h1>
            <div className={styles['divider']}/>
            <div className={styles.works_container}>
                <ContactFormCard/>
            </div>
        </div>)
}

export default ContactPage;