import React from 'react';
import styles from './card.module.css';

function PortfolioCard({
                           title = "title",
                           techList = ["A", "B"],
                           description = "description",
                           src = "",
                           url = "",
                       }) {
    return (
        <div className={styles['container']}>
            {/* left */}
            <div className={styles['content-left-wrapper']}>
                <div className={styles.title}>
                    {title}
                </div>
                <div className={styles.tech_container}>{techList.map((element, i) => (
                    <div
                        className={styles.tech_badge}
                        key={'tech_' + i}>{element}</div>
                ))}</div>
                <div>{description}</div>
                <div className={styles.spacer}/>
                <div className={styles.button}
                     onClick={() => window.open(url, '_blank')}
                >Read More
                </div>
            </div>
            {/* end left */}
            {/* right */}
            <div className={styles['content-right-wrapper']}>
                <img className={styles.thumbnail}
                     src={src}
                     alt={'portfolio item'}/>
            </div>
            {/* end right */}
        </div>
    );
}

export default PortfolioCard;