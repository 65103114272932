import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  NotFoundPage,
  AboutMePage,
  PortfolioPage,
  ContactPage,
  MapGamePage
} from "../pages";
import { MainContainer } from "../containers";

function RouterView() {
  return (
    <Router>
      <MainContainer>
        <Routes>
          <Route path={"/"} exact element={<AboutMePage />} />
          <Route path={"/portfolio"} exact element={<PortfolioPage />} />
          <Route path={"/contact"} exact element={<ContactPage />} />
          <Route path={"/map-game"} element={<MapGamePage />} />
          <Route path={"*"} element={<NotFoundPage />} />
        </Routes>
      </MainContainer>
    </Router>
  );
}

export default RouterView;
