import React from 'react';
import styles from './style.module.css';
import logo from '../../assets/img/logo512.png';
import {Link} from "react-router-dom";
import {LINKS} from "../../constants/data";

function HeaderBar() {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.content_wrapper}>
                    <div className={styles.name}>
                        <img className={styles.logo} src={logo} alt={'logo'}/>
                        { "{ Nathan Yang }" }
                    </div>
                    <div className={styles.spacer}/>
                    <div className={styles.links}>
                        {LINKS.map((elem, index) => (
                            <div key={'link' + index}>
                                <Link to={elem.href}>{elem.text}</Link>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
            {/*<div className={styles.placeholder}/>*/}

        </>
    )
}

export default HeaderBar;