import React from 'react';
import styles from './style.module.css';
import {PortfolioCard} from '../../components';
import canberry from '../../assets/img/canberry.png';
import can1 from '../../assets/img/can2.png';
import eap from '../../assets/img/eap-2.png';
// import tanis from '../../assets/img/tanis.png';

const WORKS = [
    {
        title: "Canberry Real Estate iOS Mobile App",
        description: "This is a full stack project as I individually completed both the front-end and back-end development.",
        thumbnail: canberry,
        // thumbnail: "https://picsum.photos/id/160/600/800",
        url: "https://apps.apple.com/au/app/canberry/id1566620050",
        stacks: ["NodeJS", "Flutter", "MongoDB", "MySQL", "Wordpress"]
    },
    {
        title: "Canberry Real Estate Website",
        description: "A quick full stack project implemented within two months.",
        thumbnail: can1,
        // thumbnail: "https://picsum.photos/id/119/600/800",
        url: "https://www.canberryproperties.com.au",
        stacks: ["NodeJS", "NextJS", "MongoDB", "Wordpress", "AWS S3"]

    },
    {
        title: "Origo Online Learning Platform",
        description: "Participated in this project to implement front-end with ReactJS.",
        thumbnail: eap,
        // thumbnail: "https://picsum.photos/id/1048/600/800",
        url: "https://eap-space.com.au",
        stacks: ["NodeJS", "NextJS", "MongoDB"]

    },
]

function PortfolioPage() {
    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Portfolio</h1>
            <div className={styles.divider} />
            <p>Here are some of my previous work.</p>
            <div className={styles.works_container}>
                {WORKS.map((element, i) => (<div key={'portfolio_' + i}>
                    <PortfolioCard
                        title={element.title}
                        description={element.description}
                        src={element.thumbnail}
                        url={element.url}
                        techList={element.stacks}
                    />
                </div>))}
            </div>
        </div>)
}

export default PortfolioPage;